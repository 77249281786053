<template lang="" >
    <div class="main-container">
         <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
                <b-col class="user-icon">
                    <a  @click="$router.push('notifications')">
                        <img v-if="!hasNotifications" src="../assets/notification.png" alt="">
                        <img v-if="hasNotifications" src="../assets/has-notifications.png" alt="">
                    </a>

                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <b-row>
                <b-col class="name">
                <h4>Hello  {{ drivername }},</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <button v-if="end_shift_sugar" class="end-shift-button" v-show="can_end" @click="endShiftSugar" >
                        <div>
                            <h5>
                                <img src="../assets/power-white-icon.png" alt=""> 
                                <span> {{ endshift }}</span>
                            </h5>
                        </div>
                    </button>
                    <button v-if="!end_shift_sugar" class="end-shift-button" v-show="can_end" @click="endShift" >
                        <div>
                            <h5>
                                <img src="../assets/power-white-icon.png" alt=""> 
                                <span> {{ endshift }}</span>
                            </h5>
                        </div>
                    </button>
                    <div class="active-load-container" >
                        <h6 class="red-text">
                            Task Completed                       
                        </h6>   
                        <div class="active-load-content">
                            <b-container class="bv-example-row">
                                <b-row class="justify-content-md-center">
                                    <b-col> 
                                        <img src="../assets/pin-icon.png" alt="">
                                    </b-col>
                                    <b-col cols="10" class="trip-location">
                                        <h3>
                                        {{ detailName.name }}
                                        </h3>
                                        <label for="">  {{ detailName.location_name }}</label>
                                    </b-col>
                                </b-row>
                                
                                <b-row >
                                    <b-col>
                                        <div class="btns-loading-screen">
                                            <router-link to="/nextact" class="simple-btn">{{ nextActBtn }}</router-link>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>    
                        </div>    
                    </div>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {

    },
    data() {
        return {
            endshift: 'End Shift',
            user: null,
            drivername: null,
            driverapi: [],
            locationID: [],
            actID: [],
            detailID: [],
            loading: [],
            act: [],
            detailName: '',
            can_end: false,
            end_shift_sugar: false,
            hasNotifications: false,
            nextActBtn: "Next Trip",

        }
    },
    mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.locationID = JSON.parse(localStorage.getItem('locationID'));
            this.actID = JSON.parse(localStorage.getItem('actID'));
            this.detailID = JSON.parse(localStorage.getItem('detailID'));
        }
        if (!this.user) {
            this.$router.replace('/');
        }

        // Checking if the user has notifications
        axios.get(
            "https://icl.diizz.com/api/notifications/has-notifications", {
            'headers': {
                'Authorization': 'Bearer ' + this.driverapi
            }
        }
        ).then(
            response => {
                this.hasNotifications = response.data.has_notifications;
            }
        )

        let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
        console.log(currentTrip.trip);

        axios.get(`https://icl.diizz.com/api/trips/can-end-shift/${currentTrip.trip.id}`, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } }).then(response => {
            console.log(response.data)
            this.can_end = response.data.can_end;
            this.end_shift_sugar = response.data.end_shift_sugar;

            if (this.can_end && this.end_shift_sugar) {
                this.nextActBtn = "Next Trip"
            } else if (this.can_end && !this.end_shift_sugar) {
                this.nextActBtn = "Next Activity"
            }
            
        })

        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => {
                this.detailName = response.data;
                console.log(response.data)
            });
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
        },
        endShiftSugar() {
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
            let config = {
                method: 'post',
                url: `https://icl.diizz.com/api/shifts/end-sugar/${currentTrip.trip.id}`,
                headers: {
                    'Authorization': 'Bearer ' + this.driverapi,
                },
            }
            axios(config).then(
                response => {
                    console.log(response.data);
                    this.$router.replace('/endshiftsugar')
                    // this.$router.replace('/main');
                }
            )
        },
        endShift() {
            this.$router.push('/endshift')
        }
    }
}
</script>
<style lang="scss">
.btns-loading-screen {
    text-align: right;
    margin-right: 10px;
    margin-top: 20px;

    .simple-btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 10px 15px;
        background: #1F4C87;
        border-radius: 4px;
    }
}

.end-shift-button h5 {
    text-align: left;
}

.end-shift-button {
    border: none;
}
</style>