<template lang="" >
    <div class="main-container">
         <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
                <b-col class="user-icon">
                    <a @click="$router.push('notifications')">
                        <img v-if="!hasNotifications" src="../assets/notification.png" alt="">
                        <img v-if="hasNotifications" src="../assets/has-notifications.png" alt="">
                    </a>
                    <a @click="$router.push('logout')">
                        <img src="../assets/logout.png" alt="">
                    </a>

                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <b-row>
                <b-col class="name">
                <h4>Hello  {{ drivername }},</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="active-load-container" >
                        <h6 class="red-text">
                            Arrived At End Shift Location
                        </h6>   
                        <div class="active-load-content">
                            <b-container class="bv-example-row">
                                <b-row class="justify-content-md-center">
                                    <b-col> 
                                        <img src="../assets/pin-icon.png" alt="">
                                    </b-col>
                                    <b-col cols="10" class="trip-location">
                                        <h3>
                                        End Shift
                                        </h3>
                                        <label for="">  {{ end_location }}</label>
                                    </b-col>
                                </b-row>
                                
                                <b-row >
                                    <b-col>
                                        <div class="btns-loading-screen">
                                            <button @click="arrived" class="simple-btn">Arrived at Location</button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>    
                        </div>    
                    </div>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>
<script>
import { end } from '@popperjs/core';
import axios from 'axios'
export default {
    components: {

    },
    data() {
        return {
            endshift: 'End Shift',
            user: null,
            drivername: null,
            driverapi: [],
            locationID: [],
            actID: [],
            detailID: [],
            loading: [],
            act: [],
            detailName: '',
            can_end: false,
            end_shift_sugar: false,
            endShiftInfo: {},
            end_location: '',
            hasNotifications: false

        }
    },
    mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.locationID = JSON.parse(localStorage.getItem('locationID'));
            this.actID = JSON.parse(localStorage.getItem('actID'));
            this.detailID = JSON.parse(localStorage.getItem('detailID'));
        }
        if (!this.user) {
            this.$router.replace('/');
        }
        let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
        this.endShiftInfo = JSON.parse(localStorage.getItem('endShiftInfo'));
        this.end_location = this.endShiftInfo.end_location.location_name;
        console.log(this.endShiftInfo);

        // Checking if the user has notifications
        axios.get(
            "https://icl.diizz.com/api/notifications/has-notifications", {
            'headers': {
                'Authorization': 'Bearer ' + this.driverapi
            }
        }
        ).then(
            response => {
                this.hasNotifications = response.data.has_notifications;
            }
        )
    },
    methods: {

        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
        },
        arrived() {
            let shift_id = JSON.parse(localStorage.getItem('shiftID')).shift_id;
            let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));

            axios.get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + vehicleData.vehicleId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    this.milleagedata = response.data.vehiclesnapshot[0];
                    if (response.data.status === 200) {
                        localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                    }

                    let endData = {
                        odometer: this.milleagedata.canOdo,
                        fuel: this.milleagedata.canFuel,
                        shift_id: shift_id,
                    }

                    let config = {
                        method: 'post',
                        url: `https://icl.diizz.com/api/shifts/arrived-end/${this.endShiftInfo.end_trip_id}`,
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                        },
                        data: endData,
                    };
                    axios(config).then(
                        response => {
                            console.log(response.data);
                            // this.$router.replace('/endshiftsugar')
                            localStorage.removeItem('selectedActDetails');
                            localStorage.removeItem('currentTrip');
                            localStorage.removeItem('locationID');
                            localStorage.removeItem('detailID');
                            localStorage.removeItem('endShiftInfo');
                            localStorage.removeItem('shiftID');
                            localStorage.removeItem('actID');
                            this.$router.replace('/main');
                        }
                    )

                });

        }
    }
}
</script>
<style lang="scss">
.btns-loading-screen {
    text-align: right;
    margin-right: 10px;
    margin-top: 20px;

    .simple-btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 10px 15px;
        background: #1F4C87;
        border: none;
        border-radius: 4px;
    }
}

.end-shift-button h5 {
    text-align: left;
}

.end-shift-button {
    border: none;
}
</style>