<template lang="">
<div>
    <div class="notification-container">
        <b-modal id="notification-modal" centered hide-footer no-close-on-backdrop no-close-on-esc hide-header-close :title="selectedNotification.title">
            <div class="d-block">
                <p>{{ selectedNotification.description }}</p>
            </div>
            <b-container>
                <b-row align-h="end">
                    <b-button class="mt-3" variant="success" block @click="seen(selectedNotification.id, $event)" v-b-modal="notification-modal">OK</b-button>
                  </b-row>
            </b-container>

        </b-modal>
    </div>
    <div class="main-container">
        <b-container class="main-content no-y-padding">
            <b-row class="alt-header sticky">
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt="">
                        <span>Notifications </span>
                    </h6>
                </b-col>
            </b-row>
            <b-row v-for="notification in notifications" align-h="center">
                <b-col @click="openNotification(notification.id, $event)" v-b-modal="'notification-modal'">
                    <div class="notif">
                        <b-row>
                            <b-col>
                                <h3 class="title">{{ notification.title }}</h3>
                            </b-col>
                            <b-col>
                                <p class="date">
                                    {{ notification.date }}
                                </p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <p class="desc">
                                {{ notification.small_description }}
                            </p>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            user: [],
            drivername: [],
            driverapi: '',
            notifications: [],
            selectedNotification: false,
            loadingNotifications: true,

        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    methods: {
        openNotification(notification_id, event) {
            if (event) {
                event.preventDefault();
            }
            this.selectedNotification = this.notifications.find(({
                id
            }) => id == notification_id);

            // this.$refs['notification-modal'].toggle('#toggle-btn')
            // this.$bvModal.show('notification-modal');
        },
        seen(notification_id, event) {
            if (event) {
                event.preventDefault();
            }
            console.log(notification_id);

            axios.get(`https://icl.diizz.com/api/notifications/seen/${notification_id}`, {
                'headers': {
                    'Authorization': 'Bearer ' + this.driverapi
                }
            }).then(response => {
                let notifications = response.data

                notifications.forEach((item, index) => {
                    // console.log(item, index);
                    let formattedDate = moment(item.created_at).format('Do MMMM YYYY')
                    item.date = formattedDate
                    // console.log(formatted
                })

                this.notifications = notifications;
                // console.log(notifications);
            });
        }
    },
    async mounted() {
        console.log('App Mounted');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.drivername = this.user['info'].name;
        this.driverapi = this.user['info'].apikey;

        axios
            .get("https://icl.diizz.com/api/notifications/not-viewed", {
                'headers': {
                    'Authorization': 'Bearer ' + this.driverapi
                }
            })
            .then(response => {
                let notifications = response.data

                notifications.forEach((item, index) => {
                    // console.log(item, index);
                    let formattedDate = moment(item.created_at).format('Do MMMM YYYY')
                    item.date = formattedDate
                    // console.log(formatted
                })

                this.notifications = notifications;
                // console.log(notifications);
            });

    },

}
</script>

<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 20px 16px 20px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.cap {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 700;
}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}

.main-content {
    background-color: #E5E5E5 !important;
    min-height: 100vh;
}

.notif {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}

.notif .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 125% */
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0.15px;
    color: #1F4C87;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 20px;
}

.notif .date {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */
    font-family: 'Nunito', sans-serif;

    text-align: right;
    letter-spacing: 0.15px;

    color: #979797;
}

.notif .desc {
    font-weight: 400;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;

    line-height: 18px;
    /* or 112% */
    margin-top: 10px !important;
    letter-spacing: 0.15px;

    color: #292929;
}

.no-y-padding {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
}

.sticky {
    position: sticky;
    /* width: 100%; */
    margin-bottom: 10px;
    top: 0;
    /* left: -12px; */
    /* right: -12px; */
}

.modal-content {
    margin: 16px;
}
</style>
