<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt=""> 
                        <span>Task at <span class="cap"> {{ detailName.location_name }}</span> </span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <h2>{{ pageTitle }}</h2>
            <h3>{{ date }}</h3>
                <b-form @submit="onSubmit" @reset="onReset" >
                    <b-form-group id="input-group-2" >
                        <Label>Driver Name</Label>
                        <b-form-input
                        id="input-2"
                        v-model="drivername"
                        placeholder="Enter name"
                        required readonly
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" required>
                        <Label>Loading Time</Label>
                        <input type="time" readonly v-bind:value="timestamp" class="time">
                    </b-form-group>


                   <b-form-group id="input-group-4">
                        <Label>Task</Label>
                        <b-form-select v-model="selectedTrailer" @change="onTaskChange($event)" required>
                        <b-form-select-option disabled :value="null">Select Task</b-form-select-option>
                            <option v-for="row of task " :disabled="row.disabled" :key="row.id" v-bind:value="row.value">{{ row.name }}</option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label :class="{ 'text-red': !validContainer }">Container Number</Label>
                        <b-form-input
                        autocapitalize
                        id="input-2"
                        v-model="containernum"
                        placeholder="e.g FCIU6372026"
                        :class="{ notValid: !validContainer }"
                        maxlength="11"
                        ></b-form-input>
                        <span class="form-info">
                            Format should be 4 Letters and 7 numbers
                        </span>
                    </b-form-group>


                     <button class="btn btn-primary btn-block submit" :disabled=" hasActivityDetails === false">
                            <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Task Completed</span>
                    </button>
                </b-form>
        </b-container>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            pageTitle: "Container",
            user: [],
            drivername: [],
            driverapi: '',
            milleagedata: [],
            detailName: '',
            selectedTrailer: "",
            activity_details: {},
            detailID: [],
            trailer: [],
            tonnage: "",
            containernum: '',
            selectedTask: "",
            task: [
                { name: 'Pickup', value: 'Pickup' },
                { name: 'Drop', value: 'Drop' },
            ],
            validContainer: true,
            hasActivityDetails: false,
        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    watch: {
        containernum(value) {
            this.containernum = value;
            let validator = /([aA-zZ]{4})(\d{7})/g;
            console.log(this.activity_details)
            this.validContainer = validator.test(this.containernum)
        }
    },
    methods: {
        validateNo() {
            let validator = /([aA-zZ]{4})(\d{7})/g;
            this.validContainer = validator.test(this.containernum)
            console.log(this.validContainer);
        },
        onTaskChange(event) {
            // this.activity_details = {
            //     task: event
            // }
            this.selectedTask = event;
            this.hasActivityDetails = true;
            if (this.selectedTask === "Drop") {
                this.activity_details = {
                    task: "Drop Empty Container"
                }
            } else {
                this.activity_details = {
                    task: "Pickup Empty Container"
                }
            }
        },
        onSubmit(event) {
            let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));

            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + vehicleData.vehicleId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    this.milleagedata = response.data.vehiclesnapshot[0];
                    if (response.data.status === 200) {
                        localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                    }
                    let containerData = {
                        activity_details: { ...this.activity_details, container_no: this.containernum },
                        odometer: this.milleagedata.canOdo,
                        fuel: this.milleagedata.canFuel,
                        json: JSON.stringify(this.milleagedata),
                    }
                    console.log(containerData, currentTrip);
                    let config = {
                        method: 'post',
                        url: `https://icl.diizz.com/api/trips/task-completed/${currentTrip.trip.id}`,
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                        },
                        data: containerData
                    }
                    axios(config).then(response => {
                        localStorage.setItem('currentTrip', JSON.stringify(response.data));
                        if (this.selectedTask === "Drop") {
                            let nextTripId = JSON.parse(localStorage.getItem('currentTrip')).trip.id;

                            var config = {
                                method: 'post',
                                url: 'https://icl.diizz.com/api/activities/locked',
                                headers: {
                                    'Authorization': 'Bearer ' + this.driverapi,
                                },
                                data: {
                                    trip_id: nextTripId
                                }
                            };
                            axios(config)
                                .then(response => {
                                    if (response.data.length == 1) {
                                        this.$router.replace('/nextact')
                                    } else {
                                        this.$router.replace('/startnact')
                                    }
                                })
                        } else {
                            this.$router.replace('/nexttrip');
                        }
                    })
                })
        }
    },
    async mounted() {
        console.log('App Mounted');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.drivername = this.user['info'].name;
        this.driverapi = this.user['info'].apikey;
        this.shiftID = JSON.parse(localStorage.getItem('shiftID'));
        this.shiftIDtruckId = this.shiftID['created_shift'].truck_id;
        this.detailID = JSON.parse(localStorage.getItem('detailID'));

        axios
            .get("https://icl.diizz.com/api/locations", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.location = response.data));
        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.detailName = response.data));
        axios
            .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + this.shiftIDtruckId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.milleagedata = response.data.vehiclesnapshot[0]));
        axios
            .get("https://icl.diizz.com/api/trailers", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.trailer = response.data));

        let trip_info = JSON.parse(localStorage.getItem('currentTrip'));
        let completed_tasks = JSON.parse(trip_info.trip.completed_tasks);

        console.log(completed_tasks);

        let must_drop = !completed_tasks.includes('pickup_empty');
        console.log(must_drop);

        if (must_drop) {
            this.task = [
                { name: 'Pickup', value: 'Pickup', disabled: false },
                { name: 'Drop', value: 'Drop', disabled: true },
            ]
        } else {
            this.task = [
                { name: 'Pickup', value: 'Pickup', disabled: true },
                { name: 'Drop', value: 'Drop', disabled: false },
            ]
        }

    },


}
</script>
<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 15px 16px 15px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 25px;
    color: #1C447A;
    margin-bottom: 5px !important;
}

.main-container .main-content h3 {
    font-weight: 700;
    font-size: 15px;
    color: #1C447A;
    margin-bottom: 20px;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.cap {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 700;
}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}

.notValid {
    border: 1px solid #ec4949;
    color: #ec4949;
}

.text-red {
    color: #ec4949;
}

.form-info {
    font-size: 12px;
}
</style>


