import { createRouter, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Main from '../views/Main.vue'
import Shift from '../views/ShiftStart.vue'
import ShiftAct from '../views/ShiftActivity.vue'
import SelectAct from '../views/SelectActivity.vue'
import ShiftLoad from '../views/ShiftLoad.vue'
import NextTrip from '../views/NextTrip.vue'
import Logout from '../views/Logout.vue'
import Notifications from '../views/Notifications.vue'
import NextAct from '../views/NextActivity.vue'
import ETrailer from '../views/ETrailer.vue'
import EContainer from '../views/EContainer.vue'
import RContainer from '../views/RContainer.vue'
import Loading from '../views/Loading.vue'
import EndShift from '../views/EndShift.vue'
import EndShiftSugar from '../views/EndShiftSugar.vue'
import Unloading from '../views/Unloading.vue'
import StartNewActivity from '../views/StartNewActivity.vue'
import ArrivedEndShift from '../views/ArrivedEndShift'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/main',
        name: 'Main',
        component: Main
    },
    {
        path: '/shift',
        name: 'Shift',
        component: Shift
    },
    {
        path: '/shiftact',
        name: 'ShiftAct',
        component: ShiftAct
    },
    {
        path: '/shiftload',
        name: 'ShiftLoad',
        component: ShiftLoad
    },
    {
        path: '/unloading',
        name: 'Unloading',
        component: Unloading
    },
    {
        path: '/selectact',
        name: 'SelectActivity',
        component: SelectAct
    },
    {
        path: '/nexttrip',
        name: 'NextTrip',
        component: NextTrip
    },
    {
        path: '/endshift',
        name: 'EndShift',
        component: EndShift
    },
    {
        path: '/endshiftsugar',
        name: 'EndShiftSugar',
        component: EndShiftSugar
    },
    {
        path: '/arrivedendshift',
        name: 'ArrivedEndShift',
        component: ArrivedEndShift
    },
    {
        path: '/loading',
        name: 'Loading',
        component: Loading
    },
    {
        path: '/nextact',
        name: 'NextAct',
        component: NextAct
    },
    {
        path: '/etrailer',
        name: 'ETrailer',
        component: ETrailer
    },
    {
        path: '/econtainer',
        name: 'EContainer',
        component: EContainer
    },
    {
        path: '/rcontainer',
        name: 'RContainer',
        component: RContainer
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications
    },
    {
        path: '/startnact',
        name: 'StartNewActivity',
        component: StartNewActivity
    },

]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router