<template lang="">
    <div class="login-form-containter">
        <b-container fluid >
            <b-row class="login-logo">
                <b-col>
                    <img  src="../assets/main-logo.png" alt="">
                </b-col>
            </b-row>
            <b-row  align-v="center" class=" login-form">
                <b-col >
                    <Form @submit="handleLogin" :validation-schema="schema">
                        <div class="form-group">
                        <label for="username">Username</label>
                        <Field name="username" type="text" class="form-control" />
                        <ErrorMessage name="username" class="error-feedback" />
                        </div>
                        <div class="form-group">
                        <label for="password">Password</label>
                        <Field name="password" type="password" class="form-control" />
                        <ErrorMessage name="password" class="error-feedback" />
                        </div>
                        <div class="form-group">
                        <button class="btn btn-primary btn-block" :disabled="loading">
                            <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Login</span>
                        </button>
                        </div>
                        <div class="form-group">
                        <div v-if="message" class="alert alert-danger" role="alert">
                            {{ message }}
                        </div>
                        </div>
                    </Form>
                
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
 <script>

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
        username: yup.string().required("Username is required!"),
        password: yup.string().required("Password is required!"),
        });
        return {
        loading: false,
        message: "",
        schema,
        };
    },
    computed: {
        loggedIn() {
        return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
        this.$router.replace("/main");
        }
    },
    methods: {
        handleLogin(info) {
        this.loading = true;
        this.$store.dispatch("auth/login", info).then(
            () => {

            this.$router.replace("/main");
            
            },
            (error) => {
            this.loading = false;
            this.message =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            }
        );
        },
    },
    };
</script>
<style lang="css" scoped>
    h1,h2,h3,h4,h5,h6,p{
        margin: 0;
    }
    .login-form-containter{
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login-form-containter .container-fluid{
        padding: 5vh 30px;
    }
    .login-form-containter .login-logo{
        text-align: center;
    }
    .login-form-containter .login-form{
        margin-top: 100px;
    }
    .login-form-containter .login-form input{
        margin-bottom: 20px;
        color: #000000;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 15px;
    }
    .login-form-containter .login-form .btn{
        width: 100%;
        margin-top: 45px;
        display: block;
        padding: 10px 0;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        color: #ffffff;
        font-weight: 700;
        background-color: #1F4C87;
    }
</style>