<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt="">
                        <span>Start New Activity
                        </span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
             <h2>{{ createTrip }}</h2>
                <b-form @submit="onSubmit" @reset="onReset" >
                <b-form-group id="input-group-1">
                        <Label>Activity</Label>
                        <b-form-select v-model="selectedAct" @change="onChangeAct($event)" required>
                            <b-form-select-option disabled :value="null">Select Activity</b-form-select-option>
                            <option v-for="row of act" :activity_type="row.activity_type" :key="row.id" v-bind:value="row.id">{{ row.name }}</option>
                        </b-form-select>
                    </b-form-group>
                     <b-form-group id="input-group-2">
                        <Label>Location</Label>
                        <b-form-select v-model="selectedLoc" @change="onChangeLoc($event)" required>
                            <b-form-select-option disabled :value="null">Select Location</b-form-select-option>
                            <option v-for="row of locationName " :key="row.id" v-bind:value="row.id">{{ row.location_name }}</option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-3">
                        <Label>Task</Label>
                        <b-form-select v-model="selectedDetail"  @change="onChangeDet($event)" required>
                        <b-form-select-option disabled :value="null">Select Task</b-form-select-option>
                            <option v-for="row of detailName " :disabled="row.isDisabled" :task-type="row.task_type" :key="row.id" v-bind:value="row.id">{{ row.name }}</option>
                        </b-form-select>
                    </b-form-group>
                     <b-form-group id="input-group-4">
                        <Label>Trailer Number</Label>
                        <b-form-select v-model="selectedTrailer1" required>
                        <b-form-select-option disabled :value="null">Select Trailer Number</b-form-select-option>
                            <option v-for="row of trailer " :key="row.id" v-bind:value="row.vehicle_no">{{ row.vehicle_no }}</option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-4" v-if="isHidden">
                        <Label>Trailer 2 Number </Label>
                        <b-form-select v-model="selectedTrailer2" >
                        <b-form-select-option disabled :value="null">Select Trailer 2 Number</b-form-select-option>
                            <option v-for="row of trailer " :key="row.id" v-bind:value="row.vehicle_no">{{ row.vehicle_no }}</option>
                        </b-form-select>
                    </b-form-group>
                     <div class="add-trailer" v-if="!isHidden">

                    <button v-on:click="isHidden = true" >
                        <img src="../assets/plus-blue-icon-24px.png" alt="">
                        Add Trailer
                    </button>
                    <p>Click only if there is a second trailer</p>
                </div>
                <div class="add-trailer" v-if="isHidden">
                    <button v-on:click="isHidden = false" >
                        <img src="../assets/plus-blue-icon-24px.png" alt="">
                        Remove Trailer
                    </button>
                </div>
                     <button class="btn btn-primary btn-block submit" :disabled="selectedDetail === ''">
                            <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Start Trip</span>
                    </button>
                </b-form>

        </b-container>

        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            createTrip: 'Create your trip',
            activities: [],
            locationName: [],
            trailer: [],
            act: [],
            detailName: "",
            driverapi: '',
            selectedLoc: "",
            selectedAct: "",
            selectedDetail: "",
            isHidden: false,
            value: "",
            selectedTrailer1: "",
            selectedTrailer2: "",
            trip: "",
            selectingTrailer: false,
            selectedActDetails: ""
        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    async mounted() {
        console.log('App Mounted');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.drivername = this.user['info'].name;
        this.driverapi = this.user['info'].apikey;
        let trip = JSON.parse(localStorage.getItem('currentTrip'));
        console.log(trip);
        this.trip = trip;
        axios
            .get("https://icl.diizz.com/api/activities", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.act = response.data));
        axios
            .get("https://icl.diizz.com/api/trailers", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.trailer = response.data));
    },
    methods: {
        onChangeAct(event) {
            axios
                .get("https://icl.diizz.com/api/activities/" + event + "/locations", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => (this.locationName = response.data.locations)),
                localStorage.setItem('actID', event);

            axios
                .get(`https://icl.diizz.com/api/trailers/filtered/${event}`, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => (this.trailer = response.data));
        },
        onChangeLoc(event) {
            axios
                .get("https://icl.diizz.com/api/activities/" + this.selectedAct + "/locations/" + event, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    let act_details = response.data;
                    act_details.forEach(detail => {
                        // detail.task_type === "unload_sugar" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "unload_ciment" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "unload_coal" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "unload_flyash" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "unload_reject" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "unload_sugar_nos" ? detail.isDisabled = true : detail.isDisabled = false;
                        // detail.task_type === "drop_container" ? detail.isDisabled = true : detail.isDisabled = false;

                        if (detail.task_type === "unload_sugar") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "unload_ciment") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "unload_coal") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "unload_flyash") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "unload_reject") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "unload_sugar_nos") {
                            detail.isDisabled = true;
                        }
                        if (detail.task_type === "drop_container") {
                            detail.isDisabled = true;
                        }
                    })
                    this.detailName = act_details;

                    console.log(act_details)
                    // console.log(response.data)
                }),
                console.log(event),
                localStorage.setItem('locationID', event);
        },
        onChangeDet(event) {
            console.log(event);
            localStorage.setItem('detailID', event);
            this.selectedActDetails = this.detailName.find(act => act.id == event);
            localStorage.setItem('selectedActDetails', JSON.stringify(this.selectedActDetails));
        },
        onSubmit(event) {
            let shift_info = JSON.parse(localStorage.getItem('shiftID'));
            let trip_info = JSON.parse(localStorage.getItem('currentTrip'));
            let vehicleData = JSON.parse(localStorage.getItem('vehicleData'))

            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + vehicleData.vehicleId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    this.milleagedata = response.data.vehiclesnapshot[0];
                    if (response.data.status === 200) {
                        localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                    }
                    let tripData = {
                        'shift_id': shift_info.shift_id,
                        'activity_details_id': this.selectedDetail,
                        'odometer': shift_info.created_shift.odometer,
                        'fuel': shift_info.created_shift.fuel,
                        'previous_trip': trip_info.trip.id,
                        'trailer_1_id': this.selectedTrailer1,
                        'trailer_2_id': this.selectedTrailer2,
                    };
                    var config = {
                        method: 'post',
                        url: 'https://icl.diizz.com/api/trips',
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                        },
                        data: tripData
                    };
                    console.log(tripData);
                    axios(config)
                        .then(response => (
                            localStorage.setItem('currentTrip', JSON.stringify(response.data)),
                            this.$router.replace('/shiftload')
                        ));
                })
        }
    }
}
</script>
<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 17px 16px 17px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
    padding-bottom: 0;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
}

.main-container .add-trailer {
    text-align: center;
    margin-top: 50px !important;
}

.main-container .add-trailer p {
    color: #aaaaaa;
    font-size: 14px;
}

.main-container .add-trailer button {
    font-weight: 700;
    font-size: 16px;
    color: #1C447A;
    margin-bottom: 10px;
    background: none;
    border: none;

}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.main-container .add-trailer button img {
    margin-top: -5px;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 14px 0;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.cap {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 700;
}
</style>


