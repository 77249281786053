import AuthService from '../services/auth.service';
const info = JSON.parse(localStorage.getItem('user'));
const initialState = info
    ? { status: { loggedIn: true }, info }
    : { status: { loggedIn: false }, info: null };
    export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, info) {
        return AuthService.login(info).then(
            info => {
            commit('loginSuccess', info);
            if(info){
                return Promise.resolve(info);
            }else{
                return Promise.reject("Login fail, please try again");
            }
            },
            error => {
            commit('loginFailure');
            return Promise.reject(error);
            }
        );
        },
        logout({ commit }) {
        AuthService.logout();
        commit('logout');
        }
    },
    mutations: {
        loginSuccess(state, info) {
        state.status.loggedIn = true;
        state.info = info;
        },
        loginFailure(state) {
        state.status.loggedIn = false;
        state.info = null;
        },
        logout(state) {
        state.status.loggedIn = false;
        state.info = null;
        }
    }
};