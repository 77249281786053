<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt=""> 
                        <span>Unloading at <span class="cap"> {{ detailName.location_name }}</span> </span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <h2>{{ date }}</h2>
                <b-form @submit="onSubmit" @reset="onReset" >
                    <b-form-group id="input-group-2" >
                        <Label>Driver Name</Label>
                        <b-form-input
                        id="input-2"
                        v-model="drivername"
                        placeholder="Enter name"
                        required readonly
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" required>
                        <Label>Unloading Time</Label>
                        <input type="time" readonly v-bind:value="timestamp" class="time">
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Truck Milleage</Label>
                        <b-form-input
                        id="input-2"
                        v-model="milleage"
                        placeholder="Milleage"
                        :value="milleagedata.canOdo + ' Km'"
                        type="text"
                        readonly
                        required
                        ></b-form-input>
                    </b-form-group>

<!--                    <b-form-group id="input-group-8" >-->
<!--                        <Label>Fines</Label>-->
<!--                        <b-form-input-->
<!--                        id="input-2"-->
<!--                        v-model="fine"-->
<!--                        placeholder="Fines"-->
<!--                        ></b-form-input>-->
<!--                    </b-form-group>-->
                    <b-form-group id="input-group-8" >
                        <Label :class="{ 'text-red': !validContainer }">Container Number</Label>
                        <b-form-input
                        autocapitalize
                        id="input-2"
                        v-model="containernum"
                        placeholder="e.g FCIU6372026"
                        :class="{ notValid: !validContainer }"
                        maxlength="11"
                        ></b-form-input>
                        <span class="form-info">
                            Format should be 4 Letters and 7 numbers
                        </span>
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Notes</Label>
                        <b-form-textarea
                            id="textarea"
                            v-model="remarks"
                            placeholder="Notes"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                        <h6 class="red-text">
                            Validation
                        </h6>
                        <div class="my-3">
                            <input id="upload-image" class="form-control" ref="fileInput" required type="file" hidden v-on:change="pickFile" capture="camera">
                        </div>
                        <button class="upload-btn" @click="chooseFiles">
                            <img src="../assets/upload-icon.svg" alt="">
                            Upload image
                        </button>
                        <div v-if="previewImage" class="image-preview mt-3">
                            <div class="image-wrapper">
                                <div class="image" :style="{ 'background-image': `url(${previewImage})` }" alt="">
                                <img src="{{previewImage}}" alt="">
                                </div>
                                <p class="image-name">{{image_name}}</p>
                            </div>
                            <div class="icon" v-on:click="deleteImage">
                                <img src="../assets/delete-icon.svg" alt="">
                            </div>
                        </div>
                        <progress v-if="showProgress" class="progress-bar" max="100" :value.prop="uploadPercentage"></progress>
                    </b-form-group>

                    <button class="btn btn-primary btn-block submit d-flex" :disabled="file === '' || loading === true">
                        <div v-show="loading" class="mr-3">
                            <span class="spinner-border spinner-border-sm"></span>
                        </div>
                        <span class="ml-3">Unloading Completed</span>
                    </button>

                </b-form>
        </b-container>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            user: [],
            drivername: [],
            driverapi: '',
            shiftIDtruckId: '',
            milleagedata: [],
            detailName: '',
            detailID: [],
            tonnage: "",
            remarks: "",
            previewImage: null,
            image_name: '',
            file: '',
            loading: false,
            containernum: "",
            validContainer: true,
            uploadPercentage: 0,
            showProgress: false

        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    watch: {
        containernum(value) {
            this.containernum = value;
            let validator = /([aA-zZ]{4})(\d{7})/g;
            this.validContainer = validator.test(this.containernum)
        }
    },
    methods: {
        //image upload and preview methods
        chooseFiles(event) {
            event.preventDefault();
            //this.$refs.fileInput.click()

          let cameraOptions = {
            quality: 100,
            destinationType: Camera.DestinationType.DATA_URL,
            encodingType: Camera.EncodingType.JPEG,
          }
          let successPic = this.cameraCallbackVue();
          navigator.camera.getPicture(successPic, this.cameraErrorVue(), cameraOptions);
        },
        cameraCallbackVue(imageData) {
          alert('Image');
          this.previewImage = "data:image/jpeg;base64," + imageData;
        },
        cameraErrorVue(message) {
          alert('Failed because: ' + message);
        },
        selectImage() {
            this.$refs.fileInput.click()
        },
        pickFile(event) {
            let input = this.$refs.fileInput
            let file = input.files
            console.log(file);

            if (file && file[0]) {
                this.file = event.target.files[0];
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result;
                    // console.log(e.target);
                    // console.log(file);
                    this.image_name = file[0].name;
                }
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        },
        deleteImage() {
            console.log('asdlfhaksdh');
            this.file = "";
            this.previewImage = null;
            this.image_name = "";
        },
        onSubmit(event) {
            let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
            let selectedActDetails = JSON.parse(localStorage.getItem('selectedActDetails'));

            console.log(currentTrip);

            this.loading = true;
            this.showProgress = true;
            // data.append('file', this.file);

            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + vehicleData.vehicleId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    this.milleagedata = response.data.vehiclesnapshot[0];
                    if (response.data.status === 200) {
                        localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                    }
                    let unloadData = {
                        activity_details: {
                            task: selectedActDetails.name,
                            container_no: this.containernum,
                        },
                        odometer: this.milleagedata.canOdo,
                        fuel: this.milleagedata.canFuel,
                        json: JSON.stringify(this.milleagedata),
                        tonnage: this.tonnage,
                        remarks: this.remarks
                    }
                    let config = {
                        method: 'post',
                        url: `https://icl.diizz.com/api/trips/task-completed/${currentTrip.trip.id}`,
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                        },
                        data: unloadData
                    }

                    let image_config = {
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                            'content-type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {

                        }.bind(this)
                    }

                    let image_data = {
                        file: this.file,
                        trip_id: currentTrip.trip.id
                    };
                    axios.post('https://icl.diizz.com/api/upload-image',
                        image_data,
                        {
                          headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                            'content-type': 'multipart/form-data'
                          },
                          onUploadProgress: function (progressEvent) {
                            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                          }.bind(this)
                        }
                    )
                        .then(response => {
                          console.log(response);
                          this.showProgress = false;

                          axios(config).then(response => {
                            localStorage.setItem('currentTrip', JSON.stringify(response.data));
                            let nextTripId = JSON.parse(localStorage.getItem('currentTrip')).trip.id;

                            var config = {
                              method: 'post',
                              url: 'https://icl.diizz.com/api/activities/locked',
                              headers: {
                                'Authorization': 'Bearer ' + this.driverapi,
                              },
                              data: {
                                trip_id: nextTripId
                              }
                            };
                            axios(config)
                                .then(response => {
                                  this.loading = false;

                                  if (response.data.length == 1) {
                                    this.$router.replace('/nexttrip')
                                  } else {
                                    this.$router.replace('/startnact')
                                  }
                                })
                          })
                        });
                })
        }
    },
    async mounted() {
        console.log('App Mounted');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.drivername = this.user['info'].name;
        this.driverapi = this.user['info'].apikey;
        this.shiftID = JSON.parse(localStorage.getItem('shiftID'));
        this.shiftIDtruckId = this.shiftID['created_shift'].truck_id;
        this.detailID = JSON.parse(localStorage.getItem('detailID'));

        axios
            .get("https://icl.diizz.com/api/locations", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.location = response.data));
        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.detailName = response.data));
        axios
            .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + this.shiftIDtruckId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.milleagedata = response.data.vehiclesnapshot[0]));

    },


}
</script>
<style lang="css" scoped>
#input-2{
  text-transform: uppercase;
}
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 15px 16px 10px 16px;
}

.main-container .alt-header h6 {
  font-weight: 700;
  color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.cap {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 700;
}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}

.imagePreviewWrapper {
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: contain;
    background-position: center center;
}

.upload-btn {
    width: 100%;
    display: flex;
    background-color: white;
    border: 2px solid #1C447A;
    padding: 12px;
    color: #1c447a;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.upload-btn img {
    margin-right: 12px;
}

.image-preview {
    width: 100%;
    height: 56px;
    display: flex;
    border: 1px solid #dfdfdf;
    justify-content: space-between;
}

.image-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    max-width: 75%;
    overflow: hidden;
}

.image-name {
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.image-wrapper .image {
    width: 54px;
    height: 54px;
    background-color: #fff;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 16px;
}

.icon {
    height: 54px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit {
    display: flex;
    justify-content: center;
    gap: 12px
}

.notValid {
    border: 1px solid #ec4949;
    color: #ec4949;
}

.text-red {
    color: #ec4949;
}

.form-info {
    font-size: 12px;
}
.progress-bar {
  width: 100%;
  margin-top: 4px;
}
.progress-bar::-webkit-progress-bar {
  background-color: #b3b3b3;
}
.progress-bar::-webkit-progress-value {
  background-color: #1C447A;
}
</style>


