<template lang="" >
    <div class="main-container">
        <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
                <b-col class="user-icon">
                    <a  @click="$router.push('notifications')">
                        <img v-if="!hasNotifications" src="../assets/notification.png" alt="">
                        <img v-if="hasNotifications" src="../assets/has-notifications.png" alt="">
                    </a>
                    <!-- <a @click="$router.push('logout')">
                    <img src="../assets/logout.png" alt="">
                    </a> -->
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <b-row>
                <b-col class="name">
                <h4> 
                    Hello {{ drivername }},
                </h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="start-shift-button">
                        <router-link to="/selectact">
                            <h5>
                                <img src="../assets/plus-blue-icon.png" alt=""> 
                                    <span> {{ selectactivity }}</span>
                            </h5>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-row>
                <b-col>
                      <h6 class="red-text">
                            Current Shift
                        </h6>   
                </b-col>
            </b-row>
        </b-container>
        <b-container class="shift-details">
            <b-row>
                <b-col>
                    
                    <h4>Shift Info</h4>
                    <h5>Date: <strong> {{ date }}</strong></h5>
                    <h5>Start Time: <strong>{{ starttime }}</strong> </h5>
                    <h5>Truck Number: <strong>{{ truckplate.plateNumber }}</strong>  </h5>
                    <h5>Helper Name: <strong>{{ helper.name }}</strong>  </h5>
                    <h5>Shift Start Location: <strong>{{ startloc.location_name }}</strong>  </h5>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'

export default {
    components: {
    },
    data() {
        return {
            user: null,
            drivername: null,
            endshift: 'End Shift',
            selectactivity: 'Select First Activity',
            activities: [],
            activityname: [],
            locID: '',
            driverapi: '',
            starttime: "",
            date: "",
            truckplate: "",
            helper: [],
            startloc: "",
            hasNotifications: false
        }
    },
    computed: {
        starttime: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.shift = JSON.parse(localStorage.getItem('shiftID'));
            this.starttime = this.shift['created_shift'].start_time;
            this.date = this.shift['created_shift'].start_time;
            this.truckplate = JSON.parse(localStorage.getItem('vehicleData'));
        }
        if (!this.user) {
            this.$router.replace('/');
        }


        // Checking if the user has notifications
        axios.get(
            "https://icl.diizz.com/api/notifications/has-notifications", {
            'headers': {
                'Authorization': 'Bearer ' + this.driverapi
            }
        }
        ).then(
            response => {
                this.hasNotifications = response.data.has_notifications;
            }
        )

        axios
            .get("https://icl.diizz.com/api/activities", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.activities = response.data));
        this.activityname = this.activities.name;
        axios
            .get("https://icl.diizz.com/api/shifts/" + this.shift.shift_id + "/helpers", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.helper = response.data[0]));
        axios
            .get("https://icl.diizz.com/api/shifts/" + this.shift.shift_id, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.startloc = response.data[0]));

    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
        },
        locId() {
            $(".modal-backdrop").addClass("remove"),
                localStorage.setItem('locID', this.locID),
                this.$router.replace('/risk');
        }
    }
}
</script>
<style lang="scss">
.main-container .main-content h5 {
    color: #1C447A;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px !important;
}

.main-container .shift-details {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 4px;
    padding: 15px 20px;
    background-color: #1C447A;
    width: calc(100% - 20px);
    margin: 10px auto 50px !important;
    border-radius: 10px;
}

.main-container .shift-details h4 {
    color: #ffffff;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 15px;
}

.main-container .shift-details h5 {
    color: #ffffff;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px !important;
}
</style>


