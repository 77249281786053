import axios from 'axios';
const API_URL = 'https://icl.diizz.com/api/user/login';
class AuthService {
    login(info) {
        return axios
        .post(API_URL, {
            username: info.username,
            password: info.password
        })
        .then(response => {
            
            if (response.data.api_token) {
            localStorage.setItem('user', JSON.stringify(response.data));
            return response;
            }else{
                return false;
            }
        });
    }
    logout() {
        localStorage.clear();

    }
}
export default new AuthService();