<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt=""> 
                        <span>My Shift</span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <h2>{{ date }}</h2>
                <b-form  @submit="onSubmit">
                    <b-form-group >
                        <Label>Driver Name</Label>
                        <b-form-input
                        v-bind:id="driverid"
                        placeholder="Enter name"
                        required 
                        readonly
                        v-bind:value="drivername"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group >
                        <Label>Start Shift Time</Label>
                        <input type="time"  v-bind:value="timestamp" class="time">
                    </b-form-group>
                    <b-form-group id="input-group-4">
                        <Label>Truck Number</Label>
                        <b-form-select v-model="selectedtruck" @change="onChange($event)" required>
                            <option disabled selectedtruck>Truck Number</option>
                            <b-form-select-option disabled :value="null">Select Truck Number</b-form-select-option>

                            <option v-for="row of truck " :key="row.vehicleId" v-bind:value="row.vehicleId">{{ row.vehicle_no }}</option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group >
                        <Label>Helper Name</Label>
                         <Select2 v-model="selectedhelper" :options="helper" placeholder="Select Helper" required />
                    </b-form-group>
                    <b-form-group >
                        <Label>Start Shift Location</Label>
                        <b-form-select v-model="selectedloc"  required>
                        <b-form-select-option disabled :value="null">Select Shift Location </b-form-select-option>
                        <option selectedloc v-for="row of location " :key="row.id" v-bind:value="row.id">{{ row.location_name }}</option>
                        </b-form-select>
                    </b-form-group> 
                    <b-form-group  >
                        <Label>Truck Milleage</Label>
                        <b-form-input
                        id="input-2"
                        placeholder="Milleage"
                        required 
                        readonly
                        type="text"
                        :value="milleagedata.canOdo + ' km'">
                        </b-form-input>
                    </b-form-group>

                    <button class="btn btn-primary btn-block submit" :disabled="selectedloc === ''">
                            <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Start Shift</span>
                    </button>
                </b-form>
        </b-container>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import Select2 from 'vue3-select2-component';

export default {
  components: {Select2},
  data() {
        return {
            user: [],
            date: [],
            drivername: [],
            truck: [],
            driverapi: '',
            selectedhelper: "",
            selectedtruck: "",
            location: [],
            selectedloc: '',
            helper: [],
            milleagedata: []
        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    methods: {
        onChange(event) {
            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + event, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(
                    response => {
                        this.milleagedata = response.data.vehiclesnapshot[0];
                        if (response.data.status === 200) {
                            localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                        }
                    });
        },
        onSubmit() {
            let shiftdata = {
                'truck_id': this.milleagedata.vehicleId,
                'location_id': this.selectedloc,
                'helpers': this.selectedhelper,
                "start_time": moment(this.model).format('YYYY-MM-DD HH:mm:ss'),
                'odometer': this.milleagedata.canOdo,
                'fuel': this.milleagedata.canFuel,
            }
            var config = {
                method: 'post',
                url: 'https://icl.diizz.com/api/shifts',
                headers: {
                    'Authorization': 'Bearer ' + this.driverapi,
                },
                data: shiftdata
            };
            axios(config)
                .then(response => (
                    localStorage.setItem('shiftID', JSON.stringify(response.data)),
                    localStorage.setItem('currentTrip', JSON.stringify(response.data)),
                    this.$router.replace("/shiftact")
                ));
        },
    },
    async mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
        }
        if (!this.user) {
            this.$router.replace('/home');
        }
        axios
            .get("https://icl.diizz.com/api/trucks", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.truck = response.data));
        axios
            .get("https://icl.diizz.com/api/helperlist", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.helper = response.data ));
        axios
            .get("https://icl.diizz.com/api/locations/shifts", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.location = response.data));
    },
}
</script>
<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 15px 16px 10px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
    padding-bottom: 100px;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.mb-3 {
    margin-bottom: 5px !important;
}

label {
    font-size: 12px
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}
</style>


