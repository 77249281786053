<template lang="" >
    <div class="main-container">
        <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
                <b-col class="user-icon">
                    <a  @click="$router.push('notifications')">
                        <img v-if="!hasNotifications" src="../assets/notification.png" alt="">
                        <img v-if="hasNotifications" src="../assets/has-notifications.png" alt="">
                    </a>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <b-row>
                <b-col class="name">
                <h4>Hello  {{ drivername }},</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="end-shift-button">
                        <a @click="onEndShift()" >
                            <h5>
                                <img src="../assets/power-white-icon.png" alt=""> 
                                <span> {{ endshift }}</span>
                            </h5>
                        </a>
                    </div>
                    <div class="start-shift-button">
                        <router-link to="/selectact">
                            <h5>
                                <img src="../assets/plus-blue-icon.png" alt=""> 
                                    <span> {{ selectactivity}}</span>
                            </h5>
                        </router-link>
                        </div>
                    <div class="active-load-container" >
                        <h6 class="red-text">
                            Activity completed
                        </h6>   
                        <div class="active-load-content">
                            <b-container class="bv-example-row">
                                <b-row class="justify-content-md-center">
                                    <b-col> 
                                        <img src="../assets/pin-icon.png" alt="">
                                    </b-col>
                                    <b-col cols="10" class="trip-location">
                                        <h3>
                                        {{ detailName.name }}
                                        </h3>
                                        <label for="">  {{ detailName.location_name }}</label>
                                    </b-col>
                                </b-row>
                                

                            </b-container>    
                        </div>    
                    </div>
                </b-col>
            </b-row>
        </b-container>
          <b-container class="shift-details">
            <b-row>
                <b-col>
                    <h4>Activity Info</h4>
                    <h5>Start: <strong>{{ actinfo.start_time }}</strong> </h5>
                    <h5>End: <strong>{{ actinfo.end_time }}</strong></h5>
                    <h5>Activity: <strong>{{ actinfo.activity }}</strong></h5>
                    <h5>Location Start: <strong>{{ actinfo.start_location }}</strong></h5>
                    <h5>Task: <strong>{{ actinfo.start_task }}</strong></h5>
                    <h5>Location End: <strong>{{ actinfo.end_location }}</strong></h5>
                    <h5>Task: <strong>{{ actinfo.end_task }}</strong></h5>

                    <!-- <h5>Start Time: <strong>{{ starttime }}</strong> </h5> -->
                    <!-- <h5>Truck Number: <strong>{{ truckplate.plateNumber }}</strong>  </h5>
                    <h5>Helper Name: <strong>{{ helper.name }}</strong>  </h5>
                    <h5>Shift Start Location: <strong>{{ startloc.location_name }}</strong>  </h5> -->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            endshift: 'End Shift',
            user: null,
            drivername: null,
            driverapi: [],
            locationID: [],
            selectactivity: 'Select New Activity',
            actID: [],
            detailID: [],
            loading: [],
            act: [],
            detailName: '',
            starttime: '',
            endtime: '',
            link: "",
            date: "",
            tripID: [],
            actinfo: [],
            hasNotifications: false
        }
    },
    computed: {
        starttime: function () {
            return moment(this.model).format('HH:mm')
        },
        endtime: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.detailID = JSON.parse(localStorage.getItem('detailID'));
            this.actID = JSON.parse(localStorage.getItem('actID'));
            this.locationID = JSON.parse(localStorage.getItem('locationID'));
            this.shift = JSON.parse(localStorage.getItem('shiftID'));
            this.date = this.shift['created_shift'].start_time;
            this.truckplate = JSON.parse(localStorage.getItem('vehicleData'));
            this.tripID = JSON.parse(localStorage.getItem('currentTrip')).trip.id;
        }
        if (!this.user) {
            this.$router.replace('/');
        }

        // Checking if the user has notifications
        axios.get(
            "https://icl.diizz.com/api/notifications/has-notifications", {
            'headers': {
                'Authorization': 'Bearer ' + this.driverapi
            }
        }
        ).then(
            response => {
                this.hasNotifications = response.data.has_notifications;
            }
        )

        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.detailName = response.data));
        axios
            .get("https://icl.diizz.com/api/activities/" + this.actID + "/locations/" + this.locationID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.link = response.data[0]));
        axios
            .get("https://icl.diizz.com/api/activities/current/" + this.tripID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => { this.actinfo = response.data; console.log(response.data) });
    },
    methods: {
        onEndShift() {
            this.$router.push('/endshift')
            // let shift = JSON.parse(localStorage.getItem('shiftID'));
            // console.log(shift);
            // // this.$router.replace('/endshift')
            // let config = {
            //     method: 'post',
            //     url: `https://icl.diizz.com/api/shifts/end/${shift.shift_id}`,
            //     headers: {
            //         'Authorization': 'Bearer ' + this.driverapi,
            //     },
            // }
            // axios(config).then(
            //     response => {
            //         console.log(response.data);
            //         this.$router.replace('/endshift')
            //     }
            // )
        },
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
        },
    }
}
</script>
<style lang="scss">
.btns-loading-screen {
    text-align: right;
    margin-right: 10px;
    margin-top: 20px;

    .simple-btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 10px 15px;
        background: #1F4C87;
        border-radius: 4px;
    }
}
</style>