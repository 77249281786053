<template lang="" >
    <div class="main-container">
        <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
                <b-col class="user-icon">
                    <a  @click="$router.push('notifications')">
                        <img v-if="!hasNotifications" src="../assets/notification.png" alt="">
                        <img v-if="hasNotifications" src="../assets/has-notifications.png" alt="">
                    </a>

                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <b-row>
                <b-col class="name">
                <h4>Hello  {{ drivername }},</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="end-shift-button"  v-show="false" >
                        <router-link to="/endshift" >
                            <h5>
                                <img src="../assets/power-white-icon.png" alt=""> 
                                <span> {{ endshift }}</span>
                            </h5>
                        </router-link>
                    </div>
                    <div class="active-load-container" >
                        <h6 class="red-text">
                           Current Task Details
                        </h6>   
                        <div class="active-load-content">
                            <b-container class="bv-example-row">
                                <b-row class="justify-content-md-center">
                                    <b-col> 
                                        <img src="../assets/pin-icon.png" alt="">
                                    </b-col>
                                    <b-col cols="10" class="trip-location">
                                        <h3>
                                        {{ detailName.name }}
                                        </h3>
                                        <label for="">  {{ detailName.location_name }}</label>
                                    </b-col>
                                </b-row>
                                
                                <b-row >
                                    <b-col>
                                        <div class="btns-loading-screen">
                                            <a @click="arrived()" class="simple-btn">Arrived at location</a>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>    
                        </div>    
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {

    },
    data() {
        return {
            endshift: 'End Shift',
            user: null,
            drivername: null,
            driverapi: [],
            locationID: [],
            actID: [],
            detailID: [],
            loading: [],
            act: [],
            detailName: '',
            link: "",
            hasNotifications: false
        }
    },
    mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.detailID = JSON.parse(localStorage.getItem('detailID'));
            this.actID = JSON.parse(localStorage.getItem('actID'));
            this.locationID = JSON.parse(localStorage.getItem('locationID'));
        }
        if (!this.user) {
            this.$router.replace('/');
        }

        // Checking if the user has notifications
        axios.get(
            "https://icl.diizz.com/api/notifications/has-notifications", {
            'headers': {
                'Authorization': 'Bearer ' + this.driverapi
            }
        }
        ).then(
            response => {
                this.hasNotifications = response.data.has_notifications;
            }
        )
        
        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.detailName = response.data, this.link = response.data, console.log(response.data)));

    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
        },
        arrived() {
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
            let config = {
                method: 'post',
                url: `https://icl.diizz.com/api/trips/update-starttime/${currentTrip.trip.id}`,
                headers: {
                    'Authorization': 'Bearer ' + this.driverapi,
                },
            };
            console.log(this.link);
            axios(config).then(response => {
                localStorage.setItem('currentTrip', JSON.stringify(response.data));
                this.$router.replace(`/${this.link.screen}`);
            })
        },
    }
}
</script>
<style lang="scss">
.btns-loading-screen {
    text-align: right;
    margin-right: 10px;
    margin-top: 20px;

    .simple-btn {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 10px 15px;
        background: #1F4C87;
        border-radius: 4px;
    }
}
</style>