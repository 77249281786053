<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt=""> 
                        <span>Loading at <span class="cap"> {{ detailName.location_name }}</span> </span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <h2>{{ date }}</h2>
                <b-form @submit="onSubmit" @reset="onReset" >
                    <b-form-group id="input-group-2" >
                        <Label>Driver Name</Label>
                        <b-form-input
                        id="input-2"
                        v-model="drivername"
                        placeholder="Enter name"
                        required readonly
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3" required>
                        <Label>Loading Time</Label>
                        <input type="time" readonly v-bind:value="timestamp" class="time">
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Truck Milleage</Label>
                        <b-form-input
                        id="input-2"
                        v-model="milleage"
                        placeholder="Milleage"
                        :value="milleagedata.canOdo + ' Km'"
                        type="text"
                        readonly
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Tonnage (Kg)</Label>
                        <b-form-input
                        id="input-2"
                        v-model="tonnage"
                        placeholder="Tonnage"
                        required
                        type="number"
                        step=".01"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Notes</Label>
                        <b-form-textarea
                            id="textarea"
                            v-model="remarks"
                            placeholder="Notes"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                     <button class="btn btn-primary btn-block submit" :disabled="tonnage === ''">
                            <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Loading Completed</span>
                    </button>
                </b-form>
        </b-container>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            user: [],
            drivername: [],
            driverapi: '',
            shiftIDtruckId: '',
            milleagedata: [],
            detailName: '',
            detailID: [],
            activity_details: {
            },
            remarks: "",
            tonnage: ""
        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },
    methods: {
        onSubmit(event) {
            let vehicleData = JSON.parse(localStorage.getItem('vehicleData'));
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));
            let selectedActDetails = JSON.parse(localStorage.getItem('selectedActDetails'));

            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + vehicleData.vehicleId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(
                    response => {
                        this.milleagedata = response.data.vehiclesnapshot[0];
                        if (response.data.status === 200) {
                            localStorage.setItem('vehicleData', JSON.stringify(response.data.vehiclesnapshot[0]))
                        }
                        let loadData = {
                            activity_details: {task: selectedActDetails.name},
                            odometer: this.milleagedata.canOdo,
                            fuel: this.milleagedata.canFuel,
                            json: JSON.stringify(this.milleagedata),
                            tonnage: this.tonnage,
                            remarks: this.remarks
                        };
                        console.log(loadData);
                        let config = {
                            method: 'post',
                            url: `https://icl.diizz.com/api/trips/task-completed/${currentTrip.trip.id}`,
                            headers: {
                                'Authorization': 'Bearer ' + this.driverapi,
                            },
                            data: loadData
                        }
                        axios(config).then(response => (
                            localStorage.setItem('currentTrip', JSON.stringify(response.data)),
                            this.$router.replace('/nexttrip')
                        ))
                    }
                );



        }
    },
    async mounted() {
        console.log('App Mounted');
        this.user = JSON.parse(localStorage.getItem('user'));
        this.drivername = this.user['info'].name;
        this.driverapi = this.user['info'].apikey;
        this.shiftID = JSON.parse(localStorage.getItem('shiftID'));
        this.shiftIDtruckId = this.shiftID['created_shift'].truck_id;
        this.detailID = JSON.parse(localStorage.getItem('detailID'));

        axios
            .get("https://icl.diizz.com/api/locations", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.location = response.data));
        axios
            .get("https://icl.diizz.com/api/activity-details/" + this.detailID, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.detailName = response.data));
        axios
            .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + this.shiftIDtruckId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.milleagedata = response.data.vehiclesnapshot[0]));

    },


}
</script>
<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 15px 16px 10px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.cap {
    text-transform: uppercase;
    margin: 0 !important;
    font-weight: 700;
}

.main-container .submit {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border-radius: 0;
    left: 0;
}

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}
</style>


