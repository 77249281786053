<template lang="">
    <div>
        <div class="main-container">
            <b-container class="alt-header">
            <b-row>
                <b-col>
                    <h6 @click="$router.go(-1)">
                        <img src="../assets/left-icon.png" alt=""> 
                        <span>End Shift</span>
                    </h6>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="main-content">
            <h2>Goodbye, </h2>
                <b-form @submit="onSubmit" @reset="onReset" >
                <b-form-group >
                        <Label>Drive Name</Label>
                        <b-form-input
                        v-bind:id="driverid"
                        placeholder="Enter name"
                        required 
                        readonly
                        v-bind:value="drivername"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-3">
                        <Label>Shift End Time</Label>
                        <input type="time" readonly v-bind:value="timestamp" class="time">
                    </b-form-group>
                    <Label>Truck Number</Label>
                                        <b-form-group id="input-group-8" >
                        <b-form-input
                        id="input-2"
                        v-model="trucknumber"
                        placeholder="Truck Number"
                        required 
                        :value="milleagedata.plateNumber"
                        >
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-8" >
                        <Label>Truck Milleage</Label>
                        <b-form-input
                        id="input-2"
                        v-model="milleage"
                        placeholder="Milleage"
                        required 
                        :value="milleagedata.canOdo + ' km'"
                        >
                        </b-form-input>
                    </b-form-group>
                    <b-form-group >
                        <Label>End Shift Location</Label>
                        <b-form-select v-model="selectedloc"  required>
                        <b-form-select-option disabled :value="null">Select End Shift Location </b-form-select-option>
                        <option selectedloc v-for="row of location " :key="row.id" v-bind:value="row.id">{{ row.location_name }}</option>
                        </b-form-select>
                    </b-form-group> 
                </b-form>
        </b-container>
        <button :disabled="selectedloc === ''" class="alt-footer" @click="endShift">
            <b-row>
                <b-col>
                    <p  >End Shift </p>
                </b-col>
            </b-row>
        </button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data() {
        return {
            user: [],
            drivername: [],
            driverapi: '',
            helper: [],
            milleagedata: [],
            shiftIDtruckId: '',
            location: [],
            selectedloc: '',
        }
    },
    computed: {
        timestamp: function () {
            return moment(this.model).format('HH:mm')
        },
        date: function () {
            return moment(this.model).format('Do MMMM YYYY')
        }
    },

    methods: {
        endShift() {
            let shift = JSON.parse(localStorage.getItem('shiftID'));
            let currentTrip = JSON.parse(localStorage.getItem('currentTrip'));

            // this.$router.replace('/main');


            axios
                .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + this.shiftIDtruckId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
                .then(response => {
                    this.milleagedata = response.data.vehiclesnapshot[0];
                    let config = {
                        method: 'post',
                        url: `https://icl.diizz.com/api/shifts/end/${shift.shift_id}`,
                        headers: {
                            'Authorization': 'Bearer ' + this.driverapi,
                        },
                        data: {
                            activity_details: {
                                task: "End Shift Trip"
                            },
                            trip_id: currentTrip.trip.id,
                            end_location: this.selectedloc,
                            odometer: this.milleagedata.canOdo,
                            fuel: this.milleagedata.canFuel,
                            json: JSON.stringify(this.milleagedata),
                        }
                    }
                    axios(config).then(
                        response => {
                            console.log(response.data);
                            localStorage.setItem('endShiftInfo', JSON.stringify(response.data));
                            this.$router.push('/arrivedendshift')
                            // this.$router.replace('/main');
                        }
                    )
                });
        }
    },
    async mounted() {
        console.log('App Mounted');
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
            this.driverapi = this.user['info'].apikey;
            this.shiftID = JSON.parse(localStorage.getItem('shiftID'));
            this.shiftIDtruckId = this.shiftID['created_shift'].truck_id;
        }
        if (!this.user) {
            this.$router.push('/home');
        }
        axios
            .get("https://icl.diizz.com/api/icl/get-vehicle-details?vehicle_id=" + this.shiftIDtruckId, { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.milleagedata = response.data.vehiclesnapshot[0]));

        axios
            .get("https://icl.diizz.com/api/locations/shifts", { 'headers': { 'Authorization': 'Bearer ' + this.driverapi } })
            .then(response => (this.location = response.data));
    },


}
</script>
<style lang="css" scoped>
.main-container .alt-header {
    background-color: #1F4C87;
    padding: 15px 16px 10px 16px;
}

.main-container .alt-header h6 {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
}

.main-container .alt-header span {
    margin-left: 12px;
    top: 1px;
    position: relative;
}

.main-container .main-content {
    background: #ffffff;
}

.main-container .main-content h2 {
    font-weight: 700;
    font-size: 20px;
    color: #1C447A;
    margin-bottom: 20px;
    font-family: 'Nunito', sans-serif;
}

.main-container .main-content form {
    margin: 20px 0 !important;
}

.main-container .main-content .time {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-container .alt-footer {
    position: fixed;
    bottom: 0;
    background-color: #1F4C87;
    padding: 12px 0;
    width: 100%;
    border: none;
}

.main-container .alt-footer .col {
    text-align: center;
}

.main-container .alt-footer p {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin: 0;
}

.main-container .alt-footer:disabled {
    background-color: #979797;
    border-color: #979797;
    opacity: 1;
}
</style>


