<template lang="" >
    <div class="main-container">
        <b-container class="header">
            <b-row>
                <b-col>
                    <img src="../assets/icl-white-logo.png" alt="">
                </b-col>
               
            </b-row>
        </b-container>
        <b-container class="main-content logout">
            <b-row>
                <b-col class="name">
                <h4 class="text-center"> Logout</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="text-center space-30">
                        Are you sure  <br>
                        you want to logout?
                    </p>
                </b-col>
            </b-row>
            <b-row class="space-30">
                <b-col>
                    <button class="cancel" @click="$router.go(-1)">
                        <span>Cancel</span>
                    </button>
                </b-col>
                <b-col>
                    <button class="confirm" @click="logOut()">
                        <span>Confirm</span>
                    </button>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>
<script>
export default {
    components: {
    
    },
    data() {
        return {
            user : null,
            drivername:null,
            startshift: 'Start New Shift',    
        }
    },
    mounted(){
        if (localStorage.getItem('user')){
            this.user = JSON.parse(localStorage.getItem('user'));
            this.drivername = this.user['info'].name;
        }
        if (!this.user) {
        this.$router.replace('/');
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.replace('/');
            }
    }
}
</script>
<style lang="scss">
.space-30{
    margin-top: 30px !important;
}
p{
    line-height: 1.2;
}
.main-container .main-content {
    padding-bottom: 50px;
    max-height: 90vh;
    overflow-y: scroll;
}
label{
        font-size:12px
    }
    .mb-3 {
        margin-bottom: 5px !important;
    }
    h1,h2,h3,h4,h5,h6,p{
        margin: 0 !important;
    }
    .modal-backdrop.show
    a{
        text-decoration: none !important;
    }
    .main-container .header{
        background-color: #1F4C87;
        padding: 12px 16px 6px 16px;
    }
    .main-container .header .user-icon{
        text-align: right;
    }
    .main-container .main-content{
        padding-top: 25px;
        background-color: #ffffff;
    }
    .main-container .main-content .name h4{
        color: #1C447A;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
        font-size: 18px;
    }
    .main-container .main-content .start-shift-button{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 15px 20px;
        background-color: #ffffff;
        margin-top: 25px;
    }
    .main-container .main-content .start-shift-button h5{
        color: #1C447A;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
    }
    .main-container .main-content .start-shift-button span{
        margin-left: 18px;
        top: 3px;
        position: relative;
    }
    .main-container .main-content .end-shift-button{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 15px 20px;
        background-color: #1C447A;
        margin-top: 25px;
        bottom: 20px;
        width: calc(100% - 24px);
    }
    .main-container .main-content .end-shift-button h5{
        color: #ffffff;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
    }
    .main-container .main-content .end-shift-button span{
        margin-left: 18px;
        top: 3px;
        position: relative;
    }
    .main-container .main-content .start-loading-button{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 15px 20px;
        background-color: #ffffff;
        margin-top: 25px;
    }
    .main-container .main-content .start-loading-button button{
        color: #1C447A;
        font-size: 16px;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
        background: none;
        border: none;
        padding: 0;
        outline: none;
    }
    .main-container .main-content .start-loading-button button:hover,.main-container .main-content .start-loading-button button:focus{
        border: none;
        outline: none;
        box-shadow: none;
    }
    #modal-1 > div > div > div.modal-footer {
        display: none;
    }
    #modal-1 > div > div > div.modal-footer  button.btn.btn-secondary.btn-md.btn,#modal-1 > div > div > div.modal-footer > button.btn.btn-primary.btn-md.btn.btn-primary{
        background: none;
        border: none;
        text-transform: uppercase;
        color:#1C447A;
        font-weight: 500;
    }
    .form-check-input:checked{
        background-color: #1C447A !important;
        border-color: #1C447A !important;
    }
    .form-check{
        margin: 20px 0;
    }
    .main-container .main-content .start-loading-button span{
        margin-left: 18px;
        top: 3px;
        position: relative;
    }
    
    .main-container .main-content .active-load-container {
        margin-top: 40px;
    }
    .main-container .main-content .active-load-container .red-text{
        color: #8EC541;
        font-family: 'Nunito', sans-serif;
        font-size: 16px;
        font-weight: 700;
    }
    .main-container .main-content .active-load-container .active-load-content{
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 15px 20px;
        background-color: #ffffff;
        margin-top: 25px;
    }
    .main-container .main-content .active-load-container .active-load-content .container{
        padding: 0;
    }
    .main-container .main-content .active-load-container .active-load-content .col{
        padding: 0;
    }
    .main-container .main-content .active-load-container .active-load-content  .trip-location{
        
        h3{
            color: #1C447A;
            font-family: 'Nunito', sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.3;
        }
        p{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #292929;
        }
    }
    .main-container .main-content .active-load-container .active-load-content .col-10 {
    flex: 0 0 auto;
    width: 90.333333%;
}
.modal-backdrop.fade.remove{
    display: none;
}
.filters__filter-items__type{
    label{
        margin: 5px 0;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.15px;
    }
    input{
        margin-right: 5px;
    }
}
.btn-content{
        border-top: 1px solid #E5E5E5;
        margin-top: 20px;
        text-align: right;
        padding-top: 10px;
        .go-btn{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            background: none;
            border: none;;
            letter-spacing: 0.5px;
            color: #1F4C87;
            text-decoration: none !important;
        }
    }
.cancel{
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    color: #1C447A;
    background-color: #ffffff;
    border: 1px solid #1C447A;
    border-radius: 4px;
    width: 100px;
    padding: 10px 0;
}
.confirm{
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    color: #ffffff;
    background-color: #1C447A;
    border: 1px solid #1C447A;
    border-radius: 4px;
    width: 100px;
    padding: 10px 0;
}
.name h4.text-center{
    font-size: 25px !important;
}
.main-content.logout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
}
</style>